import React, { useEffect } from "react";
import { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { subscribe, unsubscribe } from './events';

function EarningsPage(props)
{
    const [lifetimeEarnings, setLifetimeEarnings] = useState(0);
    const [lifetimeReports, setLifetimeReports] = useState(0);
    const [monthlyBreakdown, setMonthlyBreakdown] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchCommissionData = () => {
        const url = process.env.REACT_APP_API_END_POINT + 'agentearnings/'
        fetch(url, {
            headers: {
                'Accept': 'application/json',
            },
            method: "GET",
            credentials: "include"
        })
        .then((resp) => resp.json())
        .then((data) => {
            if (data['success']) {
                setLifetimeEarnings(data['lifetime_earnings_pence'])
                setLifetimeReports(data['lifetime_reports'])
                setMonthlyBreakdown(data['monthly_breakdown'])
                setLoading(false)
            }
        })
    }

    useEffect(() => {
        fetchCommissionData();
        const handleUpdateMessage = (body) => {
            fetchCommissionData();
        }
        subscribe('newCommissionPayment', handleUpdateMessage)
        return () => {
            unsubscribe('newCommissionPayment', handleUpdateMessage)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    

    const getMainContent = () => {
        if (loading) return <FontAwesomeIcon className="spinner" icon={solid("spinner")} style={{color: "#000000", height: "50px", width: "50px" }} />
        return (
            <div className="earningsReport">
                <p className="grandTotal">Lifetime earnings: &pound;{(lifetimeEarnings / 100).toFixed(2)} - from {lifetimeReports} {lifetimeReports === 1 ? 'report' : 'reports'}</p>
                <div className="mainGrid">
                    {
                        monthlyBreakdown.map((mb) => {
                            return (
                                <>
                                    <div className="monthlyTotal">{mb.month} (Total: &pound;{(mb.total_pence / 100).toFixed(2)}, {mb.payment_status})</div>
                                    <div className="amount heading">Amount</div>
                                    <div className="assessor_ref heading">Assessor Ref</div>
                                    <div className="entry_time heading">Date &amp; Time</div>
                                    <div className="address heading">Address</div>
                                    {
                                        mb.entries.map((entry) => {
                                            return (
                                                <>
                                                    <div className="amount">&pound;{(entry.amount_pence / 100).toFixed(2)}</div>
                                                    <div className="assessor_ref">{entry.assessor_ref}</div>
                                                    <div className="entry_time">{entry.time}</div>
                                                    <div className="address">{entry.address}</div>
                                                </>
                                            )
                                        })
                                    }
                                </>
                            )
                        })
                    }
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="contentsAreaHeader">
                <div className="areaTitle">My Earnings</div>
                <div className="areaExplainer">This will list any earnings you have from Premium upgrades of Preliminary reports you generated</div>
            </div>
            <div className="contents">
                {getMainContent()}
            </div>
        </>
    )
}

export default EarningsPage;