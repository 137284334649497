import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'


function AcuityBooking(props)
{
    const routeParams = useParams();

    const [showAcuity, setShowAcuity] = useState(false);
    const [appointmentType, setAppointmentType] = useState('');
  
    const [acuityUrl, setAcuityUrl] = useState("");

    const [voucherCode, setVoucherCode] = useState(""); // gets the debounced version
    const [voucherCodeInput, setVoucherCodeInput] = useState("SAVE-100");
    const [voucherCodeMessage, setVoucherCodeMessage] = useState('');

    const [showTooManyRooms, setShowTooManyRooms] = useState(false);

    useEffect(() => {
        // look up the accuity calendar details for the given appointment type and voucher code
        if (appointmentType === '') {
            return;
        }

        const url = process.env.REACT_APP_API_END_POINT + 'acuitycheckout/';

        fetch(url, {
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                voucherCode,
                appointmentType,
                "report_path": routeParams.id + '/' + routeParams.slug
            }),
            method: "POST",
            credentials: "include"
        })
        .then((resp) => resp.json())
        .then((data) => {
            if (data['success']) {
                const reportShortUrl = encodeURIComponent(routeParams.id + '/' + routeParams.slug);
                if (data['showCalendar']) {
                    setShowAcuity(true);
                    setAcuityUrl('https://app.acuityscheduling.com/schedule.php?owner=' + data['acuityOwner'] + '&appointmentType=' + data['acuityAppointment'] + '&calendarID=' + data['acuityCalendar'] + '&field:14494310=' + props.reportData['report_name'] + '&field:14383626=' + reportShortUrl);
                } else {
                    setShowAcuity(false);
                }
                setVoucherCodeMessage(data['voucherMessage']);
                setShowTooManyRooms(data['showHouseTooBig']);

            } else {
                showAcuity(false);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appointmentType, voucherCode]);


    useEffect(() => {
        setVoucherCodeMessage('');
        const delayInputTimeoutId = setTimeout(() => {
            setVoucherCode(voucherCodeInput);
        }, 400);
        return () => clearTimeout(delayInputTimeoutId);
    }, [voucherCodeInput]);
    
    useEffect(() => {
        
    })

    const appointmentTypeChanged = (newAppointmentType) => {
        setAppointmentType(newAppointmentType);
        window.amplitude.track('Property Size Selected', {
            appointmentType: newAppointmentType
        });
    }

    return (
        <div className="checkout col-12 col-md-8">
            <div className="leadin text-success">An epIMS assessor will visit your property to update your EPC.</div>
            <div className="leadin text-success">You are a booking an appointment for an epIMS assessor to visit {props.reportData.report_name}</div>
            <div style={{marginTop: "20px"}}>To get started, please confirm the size of the property:</div>
            
            <div className="selectChoices">
                <div className={`selectButton ${appointmentType === '1-3' ? 'selected' : 'unselected'}`} onClick={() => appointmentTypeChanged('1-3')}>
                    <FontAwesomeIcon icon={solid("house")} style={{color: "#000000", height: "50px", width: "50px" }} />
                    <p style={{display: "inline-block"}}>1-3 Beds</p>
                </div>
                
                <div className={`selectButton ${appointmentType === '4-5' ? 'selected' : 'unselected'}`} onClick={() => appointmentTypeChanged('4-5')}>
                    <FontAwesomeIcon icon={solid("house")} style={{color: "#000000", height: "50px", width: "50px" }} />
                    <p style={{display: "inline-block"}}>4-5 Beds</p>
                </div>
                
                <div className={`selectButton ${appointmentType === '6+' ? 'selected' : 'unselected'}`} onClick={() => appointmentTypeChanged('6+')}>
                    <FontAwesomeIcon icon={solid("house")} style={{color: "#000000", height: "50px", width: "50px" }} />
                    <p style={{display: "inline-block"}}>6&#43; Beds</p>
                </div>
            </div>
            
            { appointmentType !== '' ?
                <div style={{marginTop: "20px"}}>
                    <div>
                        <span>Discount code: <input type="text" value={voucherCodeInput} onChange={e => setVoucherCodeInput(e.target.value)} /></span>
                        <span style={{marginLeft: "10px", color: "#FF0000"}}>{ voucherCodeMessage }</span>
                    </div>
                </div>
            : null }
            
            <div style={{marginTop: "20px"}}>
                { showAcuity ? <><iframe key={acuityUrl} src={acuityUrl} title="Schedule Appointment" width="100%" height="1000" frameBorder="0"></iframe><script src="https://embed.acuityscheduling.com/js/embed.js" type="text/javascript"></script></> : null }
                { showTooManyRooms ? <>Please email <a href="mailto:info@epims.co.uk">info@epims.co.uk</a> for a special quote</> : null }
            </div>
            <div style={{height: "100px"}}></div>
        </div>
    );
}

export default AcuityBooking;