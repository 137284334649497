function NavBar()
{
    const currentOrigin = window.location.origin;
    const wwwOrigin = currentOrigin.replace('app', 'www')
    return (
        <div className="navBarContainer">
            <div className="navBarLogo"><a href={wwwOrigin}><img src="epims_logo.png" alt="epIMS Logo" /></a></div>
            <div className="navBarOptions">
                <div className="navBarOption"><a href={wwwOrigin} >Landlords</a></div>
                <div className="navBarOption"><a href={wwwOrigin + '/my-free-report/?utm_source=homepage&utm_campaign=homeowner'}>Homeowners</a></div>
                <div className="navBarOption"><a href={wwwOrigin + '/about.html'}>About&nbsp;Us</a></div>
                <div className="navBarOption"><a href={wwwOrigin + '/contact.html'}>Contact&nbsp;Us</a></div>
            </div>
        </div>
    )
}

export default NavBar;