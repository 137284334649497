import React from "react";

function FreeReportLandingPage({reportData}) {

    const getFreeReport = () => {
        window.open(reportData.pdfUrl);
    }

    const getBranding = () => {
        if (!("company_name" in reportData)) {
            return (
                <>
                    <div className="epimsLogoContainer">
                        <img alt="" className="epimsLogo" src="/epims_logo_small.png" />
                    </div>
                    <div>
                    </div>
                </>
            );
        }
        if (!("logo_url" in reportData)) {
            return (
                <>
                    <div className="epimsLogoContainer">
                        <img alt="" className="epimsLogo" src="/epims_logo_small.png" />
                    </div>
                    <div className="assessorLogoContainer">
                        <p style={{margin: "0"}}>In Association With {reportData.company_name}</p>
                    </div>
                </>
            );
        }
        return (
            <>
                <div className="epimsLogoContainer">
                    <img alt="" className="epimsLogo" src="/epims_logo_small.png" />
                </div>
                <div className="assessorLogoContainer">
                    <p style={{margin: "0", display: "inline"}}>In Association With</p>
                    <img alt="" style={{margin: "0 0 0 10px", display: "inline"}} className="assessorLogo" src={reportData.logo_url} />
                </div>                
            </>
        )
    }

    return (
        <div className="freeReportLandingPage">
            <div className="logosArea">
                <div className="singleColumnLayout">
                    <div className="logos">
                        {getBranding()}
                    </div>
                </div>
            </div>
            <div className="body">
                <div className="singleColumnLayout">
                    <h1 className="onBlue">Download your Complimentary Energy Efficiency Report</h1>
                    <h2 className="onBlue">for {reportData.short_title}</h2>
                    <div className="mainTextLayout">
                        <div className="leftCol">
                            <img alt="" className="imgCouple" src="/couple_looking_at_report.jpg" />
                            <img alt="" className="imgFreeReport" src="/epimsFreeReportCover.jpg" />
                        </div>
                        <div className="rightCol">
                            <h2 className="onBlue">A Complimentary Energy Efficiency Report about your property is ready to view and download.</h2>
                            <p className="onBlue">You have received this link from your Energy Assessor because it provides access to a personalised preliminary Energy Efficiency Report about your property.</p>
                            <p className="onBlue">It contains important information about ways you can improve your EPC rating, reduce energy bills, increase the value of your property and cut CO<sub>2</sub> emissions.</p>
                            <p className="onBlue">The report is completely free of charge and it enables you to compare the impact of different improvement measures, discover grant opportunities and understand your legal obligations, if you are selling or renting your property.</p>
                            <p className="onBlue">Similar reports can cost hundreds of pounds, so take advantage of this opportunity and download your complimentary report now.</p>
                        </div>                        
                    </div>
                </div>
                <div className="greenBanner">
                    <div>
                        <p>Download your complimentary Energy Efficiency Report</p>
                    </div>
                    <div>
                        <button onClick={getFreeReport} className="btn btn-primary btn-lg">Download</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FreeReportLandingPage;